/*路由守卫*/
import router from '@/router'
//路由列表
import routerList from '@/assets/utils/routerList';
import state from "@/store/state";
//element 提示框
import { Message } from 'element-ui';
import axios from "axios";

//在跳转之前
router.beforeEach((to, from, next) => {

    // if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
    // // 如果用户未能验证身份，则 `next` 会被调用两次
    // next()


    // 把权限赋值给vuex
    state.currentPermissions=Number(window.localStorage.getItem('currentPermissions'));
    // 登录页面放行
    if (to.path == '/login') {

        next();
        //权限为100的放行
    } else if (to.meta.authority.indexOf(100) != -1) {
        axios.post(process.env.VUE_APP_URL + '/api/user/isLogin',{token:'Bearer ' + localStorage.getItem('admin_token')}).then(res=> {
            if(res.data.data == false){
                next('/login');
            }else {
                routerList.saveList();
                next();
            }
        })

        //符合权限的放行
    } else if (to.meta.authority.indexOf(Number(window.localStorage.getItem('currentPermissions'))) != -1) {
        axios.post(process.env.VUE_APP_URL + '/api/user/isLogin',{token:'Bearer ' + localStorage.getItem('admin_token')}).then(res=> {
            if(res.data.data == false){
                if (to.path == '/register' || to.path == '/project_management/my_project/accept' || to.path == '/project_management/my_project/progress_report'){
                    next();
                }else {
                    next('/login');
                }
            }else {
                routerList.saveList();
                next();
            }
        })

        //不合符权限的限制，并跳转回login页
    } else {
        Message({
            message: '无权限访问',
            type: 'error',
            duration: 3 * 1000
        })
        next('/login');
    }


})



//在跳转之后
router.afterEach(() => {

})
